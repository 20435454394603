import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './busines-list-item.module.scss';

const BusinessListItem = ({ title, icon, description, iconWrapperStyles }) => (
    <div className={styles.container}>
      <div key={title} className={clsx(iconWrapperStyles || styles.iconContainer)}>
        {icon}
      </div>
      <h5 className={styles.title}>{title}</h5>
      <p className={styles.description}>{description}</p>
    </div>
  );

BusinessListItem.defaultProps = { iconWrapperStyles: {} };

BusinessListItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  iconWrapperStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
};

export default BusinessListItem;
